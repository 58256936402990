<template>
    <div class="resumePersonnel">
        <!--        概览-->
        <div class="tab">
            <div class="tab-list">
                <div class="tab-total">{{ dataStatic.candidateCount }}</div>
                <div class="tab-content">
                    <div class="tab-num">人才库总数</div>
                    <div class="tab-day">
                        今日归档人数: <span class="tab-day-num">{{ dataStatic.nowFileCount }}</span>
                    </div>
                </div>
            </div>
            <div class="tab-list">
                <div class="tab-total">{{ dataStatic.activeCount }}</div>
                <div class="tab-content">
                    <div class="tab-num">激活总人数</div>
                    <div class="tab-day">
                        今日激活人数: <span class="tab-day-num">{{ dataStatic.nowActiveCount }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!--   批量操作     -->
        <div class="action" v-if="checkedList.length>0">
            <div class="check-all">
                <a-checkbox :checked="checkStatus" @change="onCheckAllChange"></a-checkbox>
                <a-icon style="margin-left: 5px" type="right" />
                已选择{{ checkedList.length }}项
            </div>
            <a-popconfirm
                title="你确定要激活嘛?"
                @confirm="_activationMore"
                okText="确定"
                cancelText="取消"
            >
                <a-button  class="action-button">激活人才</a-button>
            </a-popconfirm>
            <a-button v-if="checkedList.length>0" @click="_tags" class="action-button">添加标签</a-button>
        </div>
        <!--       搜索-->

        <div class="search" v-if="checkedList.length==0">
            <div class="check-all">
                <a-checkbox :checked="checkStatus" @change="onCheckAllChange"></a-checkbox>
                <a-icon style="margin-left: 5px" type="right" />
            </div>

            <div class="search-list">
                <div class="search-list-info">
                    <i class="iconfont icon-a-ziyuan3 search-list-icon"></i>
                    <div class="search-list-text">
                        {{ TypeName }}
                        <a-icon v-if="Type" @click="clearTree('Type')" class="tree-close" type="close-circle"/>
                    </div>
                    <i class="iconfont icon-xiangxia1 search-list-down"></i>
                </div>
                <a-tree-select
                    v-if="treeData"
                    show-search
                    allow-clear
                    class="search-tree"
                    :treeData="treeData.Type"
                    treeNodeFilterProp="title"
                    @select="_treeSelect"
                    labelInValue
                >
                </a-tree-select>

            </div>
            <div class="search-list" @click="() => searchModal(true)">
                <div class="search-list-info">
                    <i class="iconfont icon-a-ziyuan5 search-list-icon" :style="!isSearch()?'color: #2994FF;':''"></i>
                    <div class="search-list-text" @click.stop="closeSearch()"
                         :style="!isSearch()?'color: #2994FF;z-index:999;':''">
                        {{ isSearch() ? '筛选' : '取消筛选' }}
                    </div>
                    <i class="iconfont icon-xiangxia1 search-list-down"></i>
                </div>
                <div class="search-model" v-if="modalVisible">
                    <div class="search-group">
                        <div class="search-label">招聘需求：</div>
                            <a-select  show-search style="width: 100%;" placeholder="请选择招聘需求" v-model="NeedId" option-filter-prop="children">
                                <a-select-option v-for="item in NeedList" :value="item.Id">
                                    {{item.PositionName}}
                                </a-select-option>

                            </a-select>
                    </div>
                    <div class="search-group">
                        <div class="search-label">阶段-状态：</div>
                        <a-select show-search style="width: 48%;margin-right: 4%" placeholder="请选择阶段名称" v-model="Stage" option-filter-prop="children">
                            <a-select-option v-for="item in stageArr" :value="item.value">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                        <a-select show-search style="width: 48%;" placeholder="请选择跟进状态" v-model="DetailStage" option-filter-prop="children">
                            <a-select-option v-for="item in StageObjectDetail[Stage]" :value="item.FieldValue">
                                {{ item.FieldName }}
                            </a-select-option>
                        </a-select>
                    </div>

                    <div class="search-group">
                        <div class="search-label">渠道来源：</div>
                        <a-select show-search :style="FSource !=0?'width: 100%;':'width:50%'" placeholder="请选择渠道" v-model="FSource" option-filter-prop="children">
                            <a-select-option v-for="item in FSourceArr" :value="item.value">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                        <a-select v-if="FSource==0" show-search style="width: 45%;margin-left: 5%" placeholder="请选择详细渠道" v-model="Source" option-filter-prop="children">
                            <a-select-option v-for="item in SourceArr" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="search-group">
                        <div class="search-label">意向城市：</div>
                        <a-select show-search style="width: 100%"  placeholder="请选择意向城市" v-model="WorkPlace" option-filter-prop="children">
                            <a-select-option v-for="item in cityList" :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="search-group" >
                        <div class="search-label">应聘职位：</div>
                        <a-select show-search style="width: 100%" @change="changePosition"  placeholder="请选择应聘职位" v-model="Position" option-filter-prop="children">
                            <a-select-option v-for="item in PositionList" :value="item.PositionLabel">
                                {{ item.PositionName }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="search-group" >
                        <div class="search-label">标签：</div>
                        <a-input style="width: 100%" placeholder="请输入标签" v-model="Tags" />

                    </div>
                    <div class="search-group">
                        <div class="search-label">录入人：</div>
                        <div class="search-group-item">
                            <person
                                class="item"
                                v-bind="personObj"
                                @getData="ChangeSearchUser($event, 'CreateUserId')"
                            ></person>
                        </div>
                    </div>
                    <div class="search-group">
                        <div class="search-label">归档时间：</div>
                        <div class="search-group-item">
                            <a-range-picker style="width: 100%" @change="changeFileTime" />
<!--                            <a-date-picker  @change="changeFileTime" />-->
                        </div>
                    </div>

                    <div class="search-bottom">
                        <a-button @click.stop="toSearch" type="primary" class="search-bottom-bth">确定</a-button>
                        <a-button @click.stop="() => searchModal(false)" class="search-bottom-bth">取消</a-button>

                    </div>
                </div>
            </div>
            <div class="search-list" style="width: 200px;display: flex;align-items: center">
                <a-icon @click="toSearch" style="color: #949FB0;width: 45px;display: block;cursor: pointer;font-size: 20px"  type="search" />
                <a-input  style="border: 0px" v-model="SearchText" placeholder="候选人名字/手机号"></a-input>
            </div>

        </div >
        <!--        列表 -->
        <div class="table">

            <div class="table-list " v-for="(item,index) in tableList">

                <div class="table-group">
                    <a-checkbox
                        class="table-checkbox"
                        :checked="searckIndex(index)"
                        @change="checkChange($event,index)">
                    </a-checkbox>
                    <div class="table-left" @click="_resumeInfo(item.Id)">
                        <div class="table-base-info-text">{{ item.CreateTime }} {{ item.CreateUserName }} 录入 丨
                            {{ item.DutiesName ? item.DutiesName + '.' : item.Position + '·' }} {{ item.FSource }}
                        </div>

                    </div>
                    <div class="table-right">
                        <div class="table-right-label">阶段-状态 ：</div>
                        <div class="table-right-item">{{ stageObject[item.Stage] }}--{{ item.Status }}</div>
                    </div>
                </div>
                <div class="table-group">
                    <div class="table-left"  @click="_resumeInfo(item.Id)">
                        <div class="table-user-info-name">{{ item.Name }}</div>

                        <i v-if="item.Sex==2" class="iconfont icon-nansheng"></i>
                        <i v-if="item.Sex==1" class="iconfont icon-nv"></i>
                        <div class="table-user-info-age"> {{ item.Age ? item.Age + '岁' : '' }}
                            {{ item.Qualification ? item.Qualification + '.' : '' }} {{ item.WorkPlace }}
                        </div>
                    </div>
                    <div class="table-right">
                        <div class="table-right-label">归档时间 ：</div>
                        <div class="table-right-item">{{ item.FileTime }}</div>
                    </div>
                </div>
                <div class="table-group">
                    <div class="table-left"  @click="_resumeInfo(item.Id)" style="display: block">
                        <div class="table-need-info">
                            <div class="table-need-info-text">
                                {{ item.DepartmentName ? item.DepartmentName + ' | ' : '' }}
                                {{ item.PositionName ? item.PositionName + '| ' : '' }}
                                {{ item.Number ? item.Number + '人 | ' : '' }}
                                {{ item.DemandUserName ? item.DemandUserName + ' | ' : '' }}
                            </div>
                            <a-avatar class="table-need-info-avatar" :size="20" icon="user"
                                   v-if="item.InchargeUserNameAvatar"   :src="item.InchargeUserNameAvatar"/>
                            <div class="table-need-info-text">{{ item.InchargeUserName }}</div>
                        </div>
                        <a-tag v-if="item.Tags.length>0" v-for="tags in item.Tags">{{ tags }}</a-tag>
                    </div>
                    <div class="table-right" >
                        <a-popconfirm
                            title="你确定要激活嘛?"
                            @confirm="_activation(item.Id)"
                            okText="确定"
                            cancelText="取消"
                            placement="topLeft"
                        >
                            <a-button type="primary"   @click="_activation(item.Id)">激活</a-button>
                        </a-popconfirm>

                        <a-popconfirm
                            title="您确定要删除吗?"
                            @confirm.stop="_del(item.Id)"
                            okText="确定"
                            cancelText="取消"
                            style="z-index: 100;"
                        >
                            <a-button class="table-right-button"   @click="_del(item.Id)">删除</a-button>
                        </a-popconfirm>


                    </div>
                </div>
            </div>
        </div>
        <!--        分页-->
        <a-pagination
            v-if="total"
            v-model="Page"
            @change="ChangePage"
            :pageSize="Limit"
            :total="total"
            style="text-align: right;margin-top: 35px"
            show-less-items
            show-size-changer
            :page-size-options="pageSizeOptions"
            @showSizeChange="onShowSizeChange"
        />
<!--        <div class="foot">-->
<!--          -->


<!--        </div>-->

        <!--添加标签-->
        <a-drawer
            title="添加标签"
            width="600"
            :closable="false"
            :visible="tagStatus"
            @close="tagClose"
        >
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    候选人:
                </div>
                <span class="recommend-group-item">{{resumeName.toString()}}</span>

            </div>
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    自定义标签:
                </div>
                <div class="recommend-group-item">
                    <template v-for="(tag, index) in tagValue">
                        <a-tag
                            :key="tag"
                            :closable="closable"
                            @close="() => handleClosetag(tag)"
                        >
                            {{ tag }}
                        </a-tag>
                    </template>
                    <a-input
                        v-if="inputVisible"
                        ref="input"
                        type="text"
                        size="small"
                        :style="{ width: '78px' }"
                        :value="inputValue"
                        @change="handleInputChange"
                        @keyup.enter="handleInputConfirm"
                    />
                    <!--@blur="handleInputConfirm"-->
                    <a-tag
                        v-else
                        style="background: #fff; borderStyle: dashed;"
                        @click="showInput"
                    >
                        <a-icon type="plus"/>
                        自定义
                    </a-tag>
                </div>

            </div>


            <div
                :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="tagClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_tagAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
<!--        简历信息-->
        <a-drawer
                :title="resumeInfoId ? '简历信息' : '添加简历'"
                width="1000"
                :closable="false"
                :visible="resumeVisible"
                @close="resumeClose"

        >
            <div style="margin-bottom: 60px">
                <resumeInfo v-if="resumeVisible" :Id="resumeInfoId" @visible="resumeClose"></resumeInfo>
            </div>
        </a-drawer>


    </div>
</template>

<script>
import tableHeader from '../../components/tableHeader/filterBox';
import resumeInfo from 'pages/Hr/resumeInfo'
import person from '../../components/SelectPersonnel/SelectPersonnel'
export default {
    components: {
        tableHeader, resumeInfo,person
    },
    name: "resumePersonnel",
    data() {
        return {
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
            treeData: '',
            Page: 0,
            Limit: 10,
            tableList: [],
            selectedRowKeys: [],
            resumeId: [],
            resumeName: [],
            total: '',
            closable: true,
            listIndex: -1,
            stageObject: {
                '10': '初筛',
                '20': '用人部门筛选',
                '30': '面试',
                '40': '沟通offer',
                '50': '待入职'
            },
            dataStatic: {},
            checkStatus: false,
            checkedList: [],
            modalVisible: false,
            Type: '',
            TypeName: '所有候选人',
            NeedList:[],
            FSourceArr: [
                {name: '社会招聘', value: '0'},
                {name: '内部推荐', value: '1'},
                {name: '校园招聘', value: "2"},
                {name: '其他', value: "3"}
            ],
            FSource:undefined,
            SourceArr: [
                '58同城',
                '猎聘网',
                '拉勾网',
                '智联招聘',
                '中华英才网',
                'BOSS直聘',
                '前程无忧(51job)',
                '其他网络招聘'
            ],
            Source:undefined,
            WorkPlace:undefined,
            cityList:[],
            Position:undefined,
            PositionList:[],
            NeedId:undefined,
            Tags:'',
            TagList:[],
            personObj: {
                rank: 0, //职级 0为不选，1为选
                nums: 1, //选的数量
                department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                contacts: 'recent112', //常用联系人 不要常用联系人传空''
                selectArr: [] //要传入的数据
            },
            CreateUserId:'',
            CreateUserIdTitle:'',
            FileTime:'',
            StartFileTime:'',
            EndFileTime:'',
            SearchText:'',
            tagStatus:false,
            tagValue:[],
            inputValue:'',
            inputVisible:false,
            resumeInfoId:'',
            resumeVisible:false,
            StageObjectDetail:{},
            Stage:undefined,
            stageArr: [
                {value: '10', name: '初筛'},
                {value: '20', name: '用人部门筛选'},
                {value: '30', name: '面试'},
                {value: '40', name: '沟通offer'},
                {value: '50', name: '待入职'}
            ],
            DetailStage:undefined,
        }
    },

    props: {
        //招聘进展
        ProgressNeedId: {
            type: String
        },
        ProgressStage: {
            type: String
        },

        resumeProgress: {
            type: String
        },
        OpenType: {
            type: String,
        },
    },
    created() {
        this.getCityList();
        this.getTree();
        this.loadData();
        this.get_dataStatic();
        this.positionList();
        this.getTagList();
        // this._StatusList();
        this.getNeedList();
        this.getDetailStageList();
    },
    methods: {
        getDetailStageList(){
            let self = this;
            this.$axios.get(129428,{},res=>{
                self.StageObjectDetail = res.data.data;
            })
        },
        getCityList() {
            let self = this;
            this.$axios.get(1257, {}, res => {
                self.cityList = res.data.data;
            })
        },
        getTagList() {
            let self = this;
            this.$axios.get(12923, {}, res => {
                if (res.data.code == 0) {
                   self.TagList = res.data.data;
                } else {
                    this.TagList = [];
                }
            })
        },
        positionList() {
            let self = this
            this.$axios.get(1252, {}, res => {
                if (res.data.code == 0) {
                    self.PositionList = res.data.data;
                }
            })
        },
        changePosition(){
             this.getNeedList();
        },
        get_dataStatic() {
            let self = this
            this.$axios.get(1296, {}, res => {
                if (res.data.code == 0) {
                    self.dataStatic = res.data.data;
                } else {
                    self.dataStatic = {};
                }
            })
        },
        loadData() {
            let self = this
            this.$message.loading('加载中...', 0);
            this.checkedList = [];
            this.checkStatus = false;
            let data = {
                pagesize: self.Limit,
                p: self.Page,
                Type: self.Type,
                SearchText:self.SearchText,
                FSource:self.FSource || '',
                Source:self.Source || '',
                WorkPlace:self.WorkPlace || '',
                NeedId:self.NeedId || '',
                Position: self.Position || '',
                Tags:self.Tags,
                CreateUserId:self.CreateUserId,
                StartFileTime:self.StartFileTime,
                EndFileTime:self.EndFileTime,
                ProgressStage:self.ProgressStage,
                ProgressNeedId:self.ProgressNeedId,
                // StartCreateTime:self.StartCreateTime,
                // EndCreateTime:self.EndCreateTime,


            }
            this.$axios.get(
                1299, data,
                res => {
                    this.$message.destroy()
                    if (res.data.code == 0) {
                        self.tableList = res.data.data
                        self.total = res.data.total

                    } else {
                        self.tableList = []
                        self.total = '';
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },


        onShowSizeChange(current, pageSize) {
            this.Page = 0;
            this.Limit = pageSize;
            this.loadData();
        },

        ChangePage(val) {
            this.Page = val
            this.loadData()
        },

        //搜索
        isSearch() {
            if((this.FSource && typeof(this.FSource) != 'undefined' ) || (this.Source && typeof(this.Source) != 'undefined' ) || (this.WorkPlace && typeof(this.WorkPlace) != 'undefined' ) || (this.NeedId && typeof(this.NeedId) != 'undefined' ) || (this.Position && typeof(this.Position) != 'undefined' ) || this.Tags || this.CreateUserId || this.StartFileTime || this.EndFileTime || (this.Stage && typeof(this.Stage) != 'undefined' ) || (this.DetailStage && typeof(this.DetailStage) != 'undefined' )){
                return false;

            }else{
                return true;
            }


        },
        closeSearch() {
            if (!this.isSearch()) {
                this.FSource = undefined;
                this.Source = undefined;
                this.WorkPlace = undefined;
                this.NeedId = undefined;
                this.Position = undefined;
                this.Tags = '';
                this.CreateUserId ='';
                this.CreateUserIdTitle ='';
                this.StartFileTime = ''
                this.EndFileTime = ''
                this.DetailStage = undefined,
                this.Stage = undefined;
                this.modalVisible = false;
                this.personObj.selectArr =[];
                this.Page = 0;
                this.loadData()
            }else{
                this.modalVisible = true;
            }
        },
        searchModal(val) {
            if(val && this.CreateUserId){
                let arr = {};
                arr.title = this.CreateUserIdTitle;
                arr.id =  this.CreateUserId;
                this.personObj.selectArr =[];
                this.personObj.selectArr.push(arr);
            }
            this.modalVisible = val;

        },
        toSearch() {
            this.modalVisible = false;

            this.Page = 0;
            this.loadData()
        },
        changeFileTime(date,dateString){
            this.StartFileTime = dateString[0];
            this.EndFileTime = dateString[1];

        },
        ChangeSearchUser(val,name){
            if(val.length>0){
                if(name=='CreateUserId'){
                    this.CreateUserId = val[0].id;
                    this.CreateUserIdTitle = val[0].title;
                }

            }
        },
        //树
        clearTree(name) {
            if (name == 'Type') {
                this.Type = '';
                this.TypeName = '所有候选人';
            }
            this.Page = 0;
            this.loadData()
        },
        _treeSelect(val) {
            this.Type = val.value;
            this.TypeName = val.label;
            this.Page = 0
            this.loadData()
            this.getNeedList();
        },
        getTree() {
            let self = this
            this.$axios.get(1298, {}, res => {
                if (res.data.code == 0) {
                    self.treeData = res.data.data
                }
            })
        },
        //关联的招聘需求
        getNeedList(){
            let self = this;
            this.$axios.get(12939,{
                Position: self.Position || '',
                Type: self.Type,
            },res=>{
                if(res.data.code==0){

                    self.NeedList = [];
                    self.NeedList = res.data.data;

                }else{
                    self.NeedList =[];
                }
            })
        },
        //选中
        searckIndex(val) {
            if (this.checkedList.length > 0) {
                let active = 0;
                for (let i = 0; i < this.checkedList.length; i++) {
                    if (val == this.checkedList[i]) {
                        active = 1;

                    }
                }
                if (active == 1) {
                    return true;
                }
            } else {
                return false;
            }
        },
        onCheckAllChange(e) {
            this.checkStatus = e.target.checked;
            this.checkedList = [];
            if (this.checkStatus) {
                if(this.tableList.length>0){
                    for (let i = 0; i < this.tableList.length; i++) {
                        this.checkedList.push(i);
                    }
                }

            }
        },
        checkChange(e, val) {
            let active = 0;
            let num = 0;
            for (let i = 0; i < this.checkedList.length; i++) {
                if (val == this.checkedList[i]) {
                    active = 1;
                    num = i;
                }
            }
            if (e.target.checked) {
                //选中
                if (active != 1) {
                    this.checkedList.push(val);
                    if ((this.checkedList.length + 1) == this.Limit) {
                        this.checkStatus = true;
                    }
                }
            } else {
                //取消
                if (active == 1) {
                    if (this.checkedList.length == 1) {
                        this.checkedList = [];
                        this.checkStatus = false;
                    } else {
                        this.checkedList.splice(num, 1);
                    }

                }
            }

        },
        //激活
        //批量激活
        _activationMore(){
            if(this.checkedList.length ==0){
                this.$message.error('请先选择要激活的简历');
                return false;
            }
            for(let i=0;i<this.checkedList.length;i++){
                this.resumeId.push(this.tableList[this.checkedList[i]].Id)
            }
            this._ResumeType(0);
        },
        _activation(id) {
            this.resumeId.push(id);
            this._ResumeType(0);
        },
        _ResumeType(value) {
            let self = this;
            this.$axios.post(1287, {
                ResumeType: value,
                ResumeId: JSON.stringify(self.resumeId)
            }, res => {
                if (res.data.code == 0) {
                    self.resumeId = [];
                    self.resumeName = [];
                    self.checkedList = [];
                    self.checkStatus = false;
                    self.loadData();
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //删除
        _del(id) {
            let self = this
            this.$message.loading('操作中....', 0)
            let ids = [];
            ids.push(id);
            this.$axios.get(1262, {Id: JSON.stringify(ids)}, res => {
                this.$message.destroy()
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg)
                    self.onClose();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        changeIndex(i) {
            console.log(i);
            if( this.listIndex ==i){
                return false;
            }
            this.listIndex = i;
            console.log(this.listIndex);
        },
        _emptyKey() {
            this.listIndex = -1;
        },
        //添加标签
        _tags() {
            if(this.checkedList.length ==0){
                this.$message.error('请先选择要添加标签的简历');
                return false;
            }
            for(let i=0;i<this.checkedList.length;i++){
                this.resumeId.push(this.tableList[this.checkedList[i]].Id);
                this.resumeName.push(this.tableList[this.checkedList[i]].Name);
            }
            this.tagStatus = true;
        },
        tagClose() {
            this.inputValue = '';
            this.tagStatus = false;
            this.resumeId = [];
            this.resumeName = [];
            this.tagValue = [];
            this.checkedList = [];
            this.checkStatus = false;
        },
        handleClosetag(removedTag) {
            const tags = this.tagValue.filter(tag => tag !== removedTag)
            this.tagValue = tags
        },

        showInput() {
            this.inputVisible = true
            this.$nextTick(function () {
                this.$refs.input.focus()
            })

        },

        handleInputChange(e) {
            this.inputValue = e.target.value
        },

        handleInputConfirm() {
            const inputValue = this.inputValue
            let tags = this.tagValue
            if (inputValue && tags.indexOf(inputValue) === -1) {
                tags = [...tags, inputValue]
            }
            Object.assign(this, {
                tags,
                inputVisible: false,
                inputValue: ''
            })
            this.tagValue = tags;
        },
        _tagAction() {
            let self = this;
            if (self.tagValue.length == 0) {
                this.$message.error('请先添加标签');
                return false;
            }
            this.$axios.post(1288, {
                ResumeId: JSON.stringify(self.resumeId),
                Tags: JSON.stringify(self.tagValue)
            }, res => {
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    self.tagClose();
                    self.loadData();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        _resumeInfo(id){
            this.resumeInfoId = id;
            this.resumeVisible = true;
        },
        resumeClose() {
            this.resumeInfoId = '';
            this.resumeVisible = false;
        },

    }
}
</script>

<style scoped lang="less">
.resumePersonnel {
    min-height: 800px;
    background: #ffffff;
    padding: 12px 12px 20px 12px;
}
.foot{
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-right: 20px;

}
.action{
    display: flex;
    align-items: center;
    flex: 1;
    margin-bottom: 20px;
    .action-button{
        width: 120px;
        margin-left: 10px;
    }
}
.check-all {
    min-width: 45px;
    height: 36px;
    border: 1px solid #E8E9EE;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    padding: 0px 5px;
}
.search {
    flex: 1;
    display: flex;
    align-items: center;



    .search-list {
        position: relative;
        min-width: 138px;
        margin-right: 8px;
        margin-left: 8px;
        border: 1px solid #E8E9EE;
        height: 36px;
        border-radius: 4px;

        .search-model {
            position: absolute;
            left: 0;
            top: 46px;
            width: 500px;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 5px;
            z-index: 1000;
            padding: 15px 18px 0px 18px;

            .search-group {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                .search-label {
                    color: #A8A9AA;
                    font-size: 12px;
                    margin-right: 6px;
                    min-width: 82px;
                }

                .search-group-item {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .search-bottom {
                display: flex;
                height: 53px;
                background: #F9F9F9;
                border-radius: 0px 0px 5px 5px;
                align-items: center;
                width: 100%;
                padding-right: 20px;
                text-align: right;
                flex-direction: row-reverse;

                .search-bottom-bth {
                    margin-left: 8px;
                }
            }
        }

        .search-list-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 0px 8px;

            .search-list-icon {
                color: #999999;
            }

            .search-list-text {
                margin: 0px 5px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .tree-close {
                    color: #999999;
                    margin-left: 5px;
                    z-index: 1000;
                    font-size: 12px;
                }
            }

            .search-list-down {
                color: #999999;
            }
        }

        .search-tree {
            width: 100%;
            opacity: 0;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 100;
        }
    }

}

.tab {
    display: flex;
    margin-top: 12px;
    margin-bottom: 24px;

    .tab-list {
        width: 460px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 5px 12px rgba(10, 49, 83, 0.1);
        margin-right: 20px;

        .tab-total {
            color: #242424;
            font-size: 24px;
            margin-bottom: 12px;
        }

        .tab-content {
            display: flex;
            align-items: center;

            .tab-num {
                color: #242424;
                font-size: 12px;
            }

            .tab-day {
                flex: 1;
                text-align: right;
                color: #999999;
                font-size: 12px;

                .tab-day-num {
                    color: #2994FF;
                }
            }
        }
    }
}

.table {
    margin-top: 20px;
    border: 1px solid #E5E7EA;

    .table-list {
        border-bottom: 1px solid #E5E7EA;
        padding: 16px;

        .table-group {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 8px;
            padding-left: 50px;

            .table-checkbox {
                width: 50px;
            }

            .table-left {
                width: 65%;
                display: flex;
                align-items: center;


                .table-base-info-text {
                    color: #666666;
                    font-size: 13px;
                }

                .table-user-info-name {
                    color: #121316;
                    font-size: 16px;
                }

                .table-user-info-age {
                    color: #989898;
                    font-size: 13px;
                }

                .table-need-info {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;

                    .table-need-info-text {
                        color: #666666;
                        font-size: 13px;
                    }

                    .table-need-info-avatar {
                        margin-left: 8px;
                        margin-right: 5px;
                    }
                }
            }

            .table-right {
                width: 35%;
                display: flex;
                align-items: center;

                .table-right-label {
                    color: #989898;
                    font-size: 13px;
                }

                .table-right-item {
                    color: #666666;
                    font-size: 14px;
                    margin-left: 8px;
                }

                .table-right-button {
                    margin-left: 8px;
                }
            }
        }

        .table-group:first-child {
            padding-left: 0;
        }
    }

    .table-list:hover{
        background: #F7F8F9 !important;
        cursor: pointer;
    }
    .table-list:last-child {
        border-bottom: 0px;
    }

    .table-list-index {
        background: #F7F8F9 !important;
        cursor: pointer;
    }
}

.icon-nv {
    color: #FF0066;
    font-size: 18px;
}

.icon-nansheng {
    color: rgb(44, 183, 255);
    font-size: 18px;
}
.recommend-group {
    display: flex;
    margin-top: 18px;
    .recommend-group-label {
        width: 120px;
        .recommend-group-label-active {
            color: #ff0000;
        }
    }
    .recommend-group-item {
        width: 438px;
    }
}
</style>