<template>
    <div class="interviewList">

        <div class="tabs" style="align-items: center">
            <!--tab-->
            <div v-if="topData.length > 0"
                 v-for="item in topData"
                 :class="tabKey == item.value ? 'tab-item-active' : ''"
                 class="tab-item"
                 @click="_tabs(item.value,item.title)">
                <div class="tab-item-count">{{ item.num }}</div>
                <div class="tab-item-text">{{ item.title }}</div>

            </div>

            <div style="flex: 1;text-align: right">
                <a-popconfirm
                    title="您确定要下载二维码吗?"
                    @confirm="downImg()"
                    okText="确定"
                    cancelText="取消"
                >
                    <a-button style="margin-right: 20px;">面试签到二维码</a-button>
                </a-popconfirm>
            </div>
        </div>
        <!--   批量操作     -->
        <div class="action" v-if="checkedList.length>0">
            <div class="check-all" style="padding-left: 20px">
                <a-checkbox :checked="checkStatus" @change="onCheckAllChange"></a-checkbox>
                <a-icon style="margin-left: 5px" type="right" />
                已选择{{ checkedList.length }}项
            </div>

            <a-button v-if="checkedList.length>0" @click="cancelInterview" class="action-button">取消面试</a-button>
            <a-popconfirm
                title="您确定要纳入人才库吗?"
                @confirm="_ResumeType(1)"
                okText="确定"
                cancelText="取消"
            >
                <a-button  class="action-button"  >纳入人才库</a-button>
            </a-popconfirm>
            <a-popconfirm
                title="您确定要通知面试官吗?"
                @confirm="_sendInterviewer()"
                okText="确定"
                cancelText="取消"
            >
                <a-button class="action-button" v-if="checkedList.length>0">通知面试官</a-button>
            </a-popconfirm>
            <a-button v-if="checkedList.length>0" @click="_candidate()" class="action-button">通知候选人</a-button>
            <a-button v-if="checkedList.length>0" @click="_tags" class="action-button">添加标签</a-button>
        </div>
        <!--       搜索-->
        <div class="search" v-if="checkedList.length==0">
            <div class="check-all">
                <a-checkbox :checked="checkStatus" @change="onCheckAllChange"></a-checkbox>
                <a-icon style="margin-left: 5px" type="right" />
            </div>

            <div class="search-list">
                <div class="search-list-info">
                    <i class="iconfont icon-a-ziyuan3 search-list-icon"></i>
                    <div class="search-list-text">
                        {{InterviewPositionName  }}
                        <a-icon v-if="InterviewPosition" @click="clearTree('InterviewPosition')" class="tree-close" type="close-circle"/>
                    </div>
                    <i class="iconfont icon-xiangxia1 search-list-down"></i>
                </div>
                <a-tree-select
                    v-if="treeData"
                    show-search
                    allow-clear
                    class="search-tree"
                    :treeData="treeData.Position"
                    treeNodeFilterProp="title"
                    @select="_treeSelect"
                    labelInValue
                >
                </a-tree-select>
            </div>
            <div class="search-list">
                <div class="search-list-info">
                    <i class="iconfont icon-a-ziyuan4 search-list-icon"></i>
                    <div class="search-list-text">
                        {{ LeftName }}
                        <a-icon v-if="LeftType"  @click="clearTree('LeftType')" class="tree-close" type="close-circle"/>
                    </div>
                    <i class="iconfont icon-xiangxia1 search-list-down"></i>
                </div>
                <a-tree-select
                    v-if="treeData"
                    show-search
                    allow-clear
                    class="search-tree"
                    :treeData="treeData.Type"
                    treeNodeFilterProp="title"
                    @select="_treeSelect2"
                    labelInValue
                >
                </a-tree-select>
            </div>

            
            <div class="search-list" @click="() => searchModal(true)">
                <div class="search-list-info">
                    <i class="iconfont icon-a-ziyuan5 search-list-icon" :style="!isSearch()?'color: #2994FF;':''"></i>
                    <div class="search-list-text" @click.stop="closeSearch()"
                         :style="!isSearch()?'color: #2994FF;z-index:999;':''">
                        {{ isSearch() ? '筛选' : '取消筛选' }}
                    </div>
                    <i class="iconfont icon-xiangxia1 search-list-down"></i>
                </div>
                <div class="search-model" v-if="modalVisible">
                    
                    <div class="search-group">
                        <div class="search-label">招聘负责人：</div>
                        <div class="search-group-item">
                            <person
                                class="item"
                                v-bind="recruitObj"
                                @getData="ChangeSearchUser($event, 'InchargeUserId')"
                            ></person>
                        </div>
                    </div>
                    <div class="search-group">
                        <div class="search-label">面试官：</div>
                        <div class="search-group-item">
                            <person
                                class="item"
                                v-bind="InterviewerNameObj"
                                @getData="ChangeSearchUser($event, 'InterviewerName')"
                            ></person>
                        </div>
                    </div>
                    
                    <div class="search-group" >
                        <div class="search-label">面试类型：</div>
                        <a-select show-search style="width: 100%"   placeholder="请选择应聘职位" v-model="Interviewtype" option-filter-prop="children">
                            <a-select-option v-for="item in InterviewtypeObject" :value="item.value">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="search-group" >
                        <div class="search-label">面试地点：</div>
                        <a-input style="width: 100%" placeholder="请输入面试地点" v-model="InterviewAddress" />

                    </div>

                    <div class="search-bottom">
                        <a-button @click.stop="toSearch" type="primary" class="search-bottom-bth">确定</a-button>
                        <a-button @click.stop="() => searchModal(false)" class="search-bottom-bth">取消</a-button>

                    </div>
                </div>
            </div>
            <div class="search-list" style="width: 200px;display: flex;align-items: center">
                <a-icon @click="toSearch" style="color: #949FB0;width: 45px;display: block;cursor: pointer;font-size: 20px"  type="search" />
                <a-input  style="border: 0px" v-model="SearchText" placeholder="候选人名字/手机号"></a-input>
            </div>

        </div>
        <!--列表-->
        <div class="table">
<!--            @mouseover="changeIndex(index)"-->
<!--            @mouseout="_emptyKey" :class="listIndex ==index ?'table-list-index':''"-->
            <div class="table-list" v-for="(item,index) in tableList">
                <a-checkbox
                    class="table-checkbox"
                    :checked="searckIndex(index)"
                    @change="checkChange($event,index)">
                </a-checkbox>
                <div class="table-left" @click="_info(item.ResumeId)" >
                    <div class="content-info">
                        <div class="first-try"  :style="item.ArrangeInterviewType==20?'background: rgb(41, 148, 255);':item.ArrangeInterviewType==30?'background: rgb(108, 99, 255);':''"> {{ArrangeInterviewType[item.ArrangeInterviewType]}}</div>
                        <div class="InterviewType"> {{InterviewtypeArr[item.InterviewType]}}</div>
                        <div class="createTime">{{ item.TheDate }} {{ item.InterviewTime }}</div>
                        <div class="info-name">{{ item.Name }}</div>
                        <div class="info-phone">{{ item.Mobile }}</div>
                    </div>
                    <div class="table-need-info">
                        <div class="table-need-info-text">
                            {{ item.DepartmentName ? item.DepartmentName + ' | ' : '' }}
                            {{ item.PositionName ? item.PositionName + ' | ' : '' }}
                            {{ item.Number ? item.Number + '人 | ' : '' }}
                            {{ item.DemandUserName ? item.DemandUserName + ' | ' : '' }}
                        </div>
                        <a-avatar class="table-need-info-avatar" :size="20" icon="user"
                                v-if="item.InchargeUserNameAvatar"  :src="item.InchargeUserNameAvatar"/>
                        <div class="table-need-info-text">{{ item.InchargeUserName }}</div>
                    </div>
                    <div class="address">{{ item.InterviewAddress }}</div>
                </div>
                <div class="table-right">
                    <div class="interviewer">面试官：</div>
                    <div class="interviewer-name">{{ item.InterviewerName }}</div>

                    <a-popover @visibleChange="handleHoverChange(item.InterviewerId)" v-show="item.InterviewResult==0">
                        <template slot="content" v-if="userList">
                            <div class="Approvers-userList" >
                                <a-avatar class="Approvers-userList-item" :size="36" icon="user" :src="userList.Avatar"/>
                                <div class="Approvers-userList-item">
                                    <p>{{item.InterviewerName}}</p>
                                    <p>{{userList.ParentName}}</p>
                                </div>
                                <div class="Approvers-userList-item">{{userList.KeyPostName}}</div>
                            </div>
                            <a-popconfirm
                                title="您确定要发送提醒吗?"
                                @confirm="sendMsg(item.InterviewerId,item.Id)"
                                okText="确定"
                                cancelText="取消"
                            >
                                <a-button  style="margin-top: 12px" size="small" type="primary">提醒反馈</a-button>
                            </a-popconfirm>


                        </template>
                        <div class="interviewer-feedback">-{{InterviewResultObject[item.InterviewResult]}}</div>
                    </a-popover>
                    <i v-show="item.InterviewResult==2" class="iconfont icon-zan icon-result"></i>
                    <i v-show="item.InterviewResult==3" class="iconfont icon-zan icon-result-fail"></i>
                    <span class="InterviewResult">{{item.InterviewResult==3?'不合适':item.InterviewResult==2?'合适':''}}</span>
                </div>
            </div>
        </div>
        <!--        分页-->
        <a-pagination
            v-if="total"
            v-model="Page"
            @change="ChangePage"
            :pageSize="Limit"
            :total="total"
            style="text-align: right;margin-top: 35px;padding-bottom: 20px"
            show-less-items
            show-size-changer
            @showSizeChange="onShowSizeChange"
            :page-size-options="pageSizeOptions"
        />

        <!--添加标签-->
        <a-drawer
            title="添加标签"
            width="600"
            :closable="false"
            :visible="tagStatus"
            @close="tagClose"
        >
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    候选人:
                </div>
                <span class="recommend-group-item">{{resumeName.toString()}}</span>

            </div>
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    自定义标签:
                </div>
                <div class="recommend-group-item">
                    <template v-for="(tag, index) in tagValue">
                        <a-tag
                            :key="tag"
                            :closable="closable"
                            @close="() => handleClosetag(tag)"
                        >
                            {{ tag }}
                        </a-tag>
                    </template>
                    <a-input
                        v-if="inputVisible"
                        ref="input"
                        type="text"
                        size="small"
                        :style="{ width: '78px' }"
                        :value="inputValue"
                        @change="handleInputChange"
                        @keyup.enter="handleInputConfirm"
                    />
                    <!--@blur="handleInputConfirm"-->
                    <a-tag
                        v-else
                        style="background: #fff; borderStyle: dashed;"
                        @click="showInput"
                    >
                        <a-icon type="plus"/>
                        自定义
                    </a-tag>
                </div>

            </div>


            <div
                :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="tagClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_tagAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--取消面试-->
        <a-drawer
            title="取消面试"
            width="600"
            :closable="false"
            :visible="InterviewStatus"
            @close="InterviewClose"
        >
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    候选人:
                </div>
                <span class="recommend-group-item">{{resumeName.toString()}}</span>

            </div>
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    取消原因:
                </div>
                <div class="recommend-group-item">
                    <a-textarea
                        v-model="Reason"
                        placeholder="请输入取消原因"
                    />

                </div>

            </div>


            <div
                :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="InterviewClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_InterviewAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--通知候选人-->
        <a-drawer
            title="通知候选人"
            width="800"
            :closable="false"
            :visible="candidateStatus"
            @close="candidateClose"
        >
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    候选人:
                </div>
                <span class="recommend-group-item">{{resumeName.toString()}}</span>

            </div>
            <div class="recommend-group">
                <div class="recommend-group-label" style="width: 80px">
                    邮件内容:
                </div>
                <div class="recommend-group-item">
                    <div
                        id="websiteEditorElem"
                        class="websiteEditorElem"
                        style="width: 600px"
                    ></div>
                </div>

            </div>


            <div
                :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="candidateClose">
                    取消
                </a-button>
                <a-button type="primary" @click="_candidateAction()">
                    确认
                </a-button>
            </div>
        </a-drawer>
<!--        简历信息-->
        <a-drawer
            :title="resumeInfoId ? '简历信息' : '添加简历'"
            width="1000"
            :closable="false"
            :visible="resumeVisible"
            @close="onClose"

        >
            <div style="margin-bottom: 60px">
                <resumeInfo v-if="resumeVisible"  :OpenType="OpenType" :Id="resumeInfoId" :ResumeKey="'2'" @visible="onClose"></resumeInfo>

            </div>
        </a-drawer>
    </div>
</template>

<script>
import E from 'wangeditor'
import person from "../../components/SelectPersonnel/SelectPersonnel"; //选人
import resumeInfo from 'pages/Hr/resumeInfo'

export default {
    name: "interviewList",
    components: {
        person, resumeInfo
    },
    data() {
        return {
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
            listIndex:-1,
            selectedKeys: ['与我相关'],
            selectedKeys2: [],
            closable: true,
            treeData: '',
            Page: 0,
            statisticsData: [],
            Limit: 10,
            total: '',
            pageWidth: '',
            selectedRowKeys: [],
            tableList: [],
            topData: [],
            tabKey: 'nowAndLater',
            tabName: '今天及以后',
            InterviewResultObject:{"0":"未反馈","2":"合适","3":"不合适"},
            ArrangeInterviewType: {'10': '初试', '20': '复试', '30': '终试'},
            InterviewtypeArr: {'1': '现场面试', '2': '线上面试'},
            InterviewtypeObject:[{'name':'现场面试','value':'1'},{'name':'线上面试','value':'2'}],
            SearchText:'',
            checkStatus:false,
            checkedList: [],
            modalVisible: false,
            LeftType:'all',
            LeftName:'与我相关',
            InterviewPosition:'',
            InterviewPositionName:'组织架构',
            overviewTag:this.$route.query.overviewTag ? this.$route.query.overviewTag : '',
            //招聘负责人
            recruitObj: {
                rank: 0, //职级 0为不选，1为选
                nums: 1, //选的数量
                department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                contacts: 'recent112', //常用联系人 不要常用联系人传空''
                selectArr: [] //要传入的数据
            },
            //面试官
            InterviewerNameObj:{
                rank: 0, //职级 0为不选，1为选
                nums: 1, //选的数量
                department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                contacts: 'recent112', //常用联系人 不要常用联系人传空''
                selectArr: [] //要传入的数据
            },
            InchargeUserId:'',
            InterviewerName:'',
            Interviewtype:undefined,
            InterviewAddress:'',
            InchargeUserTile:'',
            InterviewerNameTitle:'',
            resumeName:[] ,
            resumeId:[],
            //添加标签
            tagStatus:false,
            tagValue:[],
            inputVisible:false,
            inputValue:'',
            //取消面试
            InterviewStatus:false,
            Reason:'',
            //通知候选人
            candidateStatus:false,
            Content: '',
            //候选人信息
            resumeInfoId:'',
            resumeVisible:false,
            userList:'',//面试官的信息
        }
    },
    props: {
        resumeProgress: {
            type: String
        },
        OpenType: {
            type: String
        }
    },
    watch: {
        resumeProgress: {//深度监听，可监听到对象、数组的变化
            handler(val, oldVal) {
                this.resumeProgress = val;
                this.searchList = [];
                this.loadData();
                this.TotalTop();
            },
            deep: true //true 深度监听
        }
    },
    created() {


        if(this.resumeProgress=='myIsDemandUserInterviewList' || this.resumeProgress=='myIsInterviewerInterviewList'){
             this.resumeProgressStatus = false;
             this.LeftType = '' ;
             this.LeftName = '全部' ;
             this.selectedKeys =[] ;
             // this.pageWidth = this.pageWidth-80;
        }
        this.interviewTotalLeft();
        // this.pageWidth = document.documentElement.clientWidth - 440

        this.loadData();
        this.TotalTop();

    },
    methods: {

        interviewTotalLeft() {
            let self = this
            this.$axios.get(1292, {}, res => {
                if (res.data.code == 0) {
                    self.treeData = res.data.data
                }
            })
        },
        loadData() {
            let self = this
            this.$message.loading('加载中...', 0);
            let data = {
                pagesize: self.Limit,
                p: self.Page,
                SearchText:self.SearchText,
                interviewTotalTop: self.tabKey,
                LeftType: self.LeftType,
                InterviewPosition:self.InterviewPosition,
                overviewTag:self.overviewTag,
                resumeProgress:self.resumeProgress,
                InchargeUserId:self.InchargeUserId,
                InterviewerName:self.InterviewerName,
                Interviewtype:self.Interviewtype || '',
                InterviewAddress:self.InterviewAddress,
            };
            this.$axios.get(
                1290, data,
                res => {
                    this.$message.destroy()
                    if (res.data.code == 0) {
                        self.tableList = res.data.data
                        self.total = res.data.total;
                        self.CodeUrl = res.data.CodeUrl;
                    } else {
                        self.CodeUrl = res.data.CodeUrl;
                        self.tableList = []
                        self.total = '';
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        _tabs(value, title) {
            this.tabKey = value;
            this.tabName = title;
            this.Page = 0;
            this.loadData();
        },
        TotalTop() {
            let self = this;
            this.$axios.get(1291, {
                overviewTag: self.overviewTag,
                resumeProgress: self.resumeProgress,
                LeftType: self.LeftType,
                InterviewPosition: self.InterviewPosition
            }, res => {
                if (res.data) {
                    self.tabKey = res.data[0].value;
                    self.tabName = res.data[0].title;
                    self.topData = res.data;
                }

            })
        },
        downImg() {
            let url = this.CodeUrl + "?attname=面试签到二维码.png"
            window.open(url);
        },


        onShowSizeChange(val,pageSize) {
            this.Page = 0;
            this.Limit = pageSize;
            this.loadData();
        },
        ChangePage(value) {
            this.Page = value;
            this.loadData();
        },
        //搜索
        searchModal(val) {
            if(val){
                if(this.InchargeUserId){
                    let arr = {};
                    arr.title =  this.InchargeUserTile;
                    arr.id =  this.InchargeUserId;
                    this.recruitObj.selectArr = [];
                    this.recruitObj.selectArr.push(arr);
                }
                if(this.InterviewerName){
                    let arr2 = {};
                    arr2.title = this.InterviewerNameTitle;
                    arr2.id = this.InterviewerName;
                    this.InterviewerNameObj.selectArr =[];
                    this.InterviewerNameObj.selectArr.push(arr2);
                }
            }
            this.modalVisible = val;
        },
        isSearch() {
            if(this.InchargeUserId || this.InterviewerName || (typeof(this.Interviewtype) !='undefined' && this.Interviewtype) || this.InterviewAddress){
                return false;
            }else{
                return true;
            }
        },
        closeSearch() {
            if (!this.isSearch()) {

                this.Interviewtype = undefined,
                this.InterviewAddress ='';
                this.InchargeUserTile ='';
                this.InterviewerNameTitle ='';
                this.InchargeUserId = '';
                this.InterviewerName ='' ;
                this.recruitObj = {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent112', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                } ;
                this.InterviewerNameObj = {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0只选人，1为都选（人 部门都选），2为只选部门，选人
                    contacts: 'recent112', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                } ;
                this.TotalTop();
                this.Page = 0;
                this.loadData()
            }else{
                this.modalVisible = true;
            }
        },
        ChangeSearchUser(val,name){
            if(val.length>0){
                if(name=='InchargeUserId'){
                    this.InchargeUserId = val[0].id;
                    this.InchargeUserTile =  val[0].title;
                }else if(name=='InterviewerName'){
                    this.InterviewerName = val[0].id;
                    this.InterviewerNameTitle = val[0].title;
                }

            }
        },
        toSearch(){
            this.modalVisible = false;
            this.TotalTop();
            this.Page = 0;
            this.loadData()
        },
        //树
        clearTree(name) {
            if (name == 'LeftType') {
                // this.LeftType = 'all';
                this.LeftName = '全部';
                this.LeftType = '';
                this.TotalTop();
            }else if(name=='InterviewPosition'){
                this.InterviewPosition ='';
                this.InterviewPositionName ='组织架构';
            }
            this.TotalTop();
            this.Page = 0;
            this.loadData()
        },
        _treeSelect(val) {

            this.LeftType = '';
            this.LeftName = '全部';
            this.InterviewPosition =val.value;
            this.InterviewPositionName =val.label;
            this.TotalTop();
            this.Page = 0
            this.loadData();
        },
        _treeSelect2(val) {

            this.LeftType = val.value;
            this.LeftName = val.label;
            this.InterviewPosition ='';
            this.InterviewPositionName ='组织架构';
            this.TotalTop();
            this.Page = 0
            this.loadData();
        },

        //选中
        searckIndex(val) {
            if (this.checkedList.length > 0) {
                let active = 0;
                for (let i = 0; i < this.checkedList.length; i++) {
                    if (val == this.checkedList[i]) {
                        active = 1;

                    }
                }
                if (active == 1) {
                    return true;
                }
            } else {
                return false;
            }
        },
        onCheckAllChange(e) {
            this.checkStatus = e.target.checked;
            this.checkedList = [];
            if (this.checkStatus) {
                if(this.tableList.length>0){
                    for (let i = 0; i < this.tableList.length; i++) {
                        this.checkedList.push(i);
                    }
                }

            }
        },
        checkChange(e, val) {
            let active = 0;
            let num = 0;
            for (let i = 0; i < this.checkedList.length; i++) {
                if (val == this.checkedList[i]) {
                    active = 1;
                    num = i;
                }
            }
            if (e.target.checked) {
                //选中
                if (active != 1) {
                    this.checkedList.push(val);
                    if ((this.checkedList.length + 1) == this.Limit) {
                        this.checkStatus = true;
                    }
                }
            } else {
                //取消
                if (active == 1) {
                    if (this.checkedList.length == 1) {
                        this.checkedList = [];
                        this.checkStatus = false;
                    } else {
                        this.checkedList.splice(num, 1);
                    }

                }
            }

        },
        _empty() {
            this.selectedRowKeys = [];
            this.resumeId = [];
            this.resumeName = [];
            this.InterviewId = [];
            this.checkedList =[];
            this.Reason = '';
            this.checkStatus = false;
        },
        _info(id){
            this.resumeInfoId  = id;
            this.resumeVisible = true;
        },
        onClose(){
            this.resumeVisible = false;
            this.resumeInfoId ='';
            this._empty();
        },
        //提醒反馈
        handleHoverChange(id){
            if (this.userList && this.userList.Id == id) {
                return false;
            }
            this.userList ='';
            let self = this;
            this.$axios.get(12936, {Id: id}, res => {
                if (res.data.code == 0) {
                    self.userList = res.data.data;

                } else {
                    self.userList = '';
                }
            })
        },
        sendMsg(userId,id){

            this.$message.loading('操作用...', 0);
            this.$axios.get(12938,{
                UserId:userId, //用户id
                Id:id,//面试id
            },res=>{
                this.$message.destroy();
                if(res.data.code==0){
                    this.$message.success(res.data.msg);
                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        //通知候选人
        _candidate() {
            for(let i=0;i<this.checkedList.length;i++){
                this.resumeId.push(this.tableList[this.checkedList[i]].ResumeId);
                this.resumeName.push(this.tableList[this.checkedList[i]].Name);
            }
            this.candidateStatus = true;
            this._wangeditor()
        },
        candidateClose() {
            this.candidateStatus = false;
            document.getElementsByClassName('websiteEditorElem').innerHTML = '';
            this._empty();

        },
        _candidateAction() {
            let self = this;
            if (!self.Content) {
                this.$message.error('请输入通知内容');
                return false;
            }
            let InterviewId = [];
            for(let i=0;i<this.checkedList.length;i++){
                InterviewId.push(this.tableList[this.checkedList[i]].InterviewId)
            }
            this.$message.loading('操作中', 0)
            this.$axios.post(1295, {
                InterviewId: JSON.stringify(InterviewId),
                Content: self.Content
            }, res => {
                this.$message.destroy();
                if (res.data.code == 0) {
                    self.candidateStatus = false;
                    self.candidateClose();
                    // self.loadData();
                    self._empty();
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        _wangeditor() {
            let wangeditor = new E('#websiteEditorElem')
            let self = this
            setTimeout(function () {
                wangeditor.customConfig.customUploadImg = (files, insert) => {
                    self.__uploadFile__(files).then(res => {
                        if (res) {
                            if (res) {
                                for (var i = 0; i < res.length; i++) {
                                    insert(res[i].FileUrl)
                                }
                            }
                        }
                    })
                }
                wangeditor.customConfig.menus = [
                    'head',
                    'bold',
                    'italic',
                    'underline',
                    'foreColor', // 文字颜色
                    'backColor', // 背景颜色
                    'list',
                    'link', // 插入链接
                    'justify', // 对齐方式
                    'quote', // 引用
                    'emoticon', // 表情
                    'image', // 插入图片
                    'table' // 表格
                ]
                // let wangeditorField =  self.fieldList[e].FieldName;
                wangeditor.customConfig.onchange = function (html) {
                    self.Content = html
                }
                // 创建一个富文本编辑器
                wangeditor.create()
                wangeditor.txt.html(self.Content)
            }, 400)
        },
        //通知面试官
        _sendInterviewer() {
            if(this.checkedList.length ==0){
                this.$message.error('请先选择要通知面试官的简历');
                return false;
            }
            let InterviewId = [];
            for(let i=0;i<this.checkedList.length;i++){
                InterviewId.push(this.tableList[this.checkedList[i]].InterviewId)
            }
            let self = this;
            this.$message.loading('操作中', 0)
            this.$axios.post(1294, {
                InterviewId: JSON.stringify(InterviewId),
            }, res => {
                this.$message.destroy();
                if (res.data.code == 0) {
                    self._empty();
                    self.loadData();
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //纳入人才库
        _ResumeType(value) {
            if(this.checkedList.length ==0){
                this.$message.error('请先选择要纳入人才库的简历');
                return false;
            }
            for(let i=0;i<this.checkedList.length;i++){
                this.resumeId.push(this.tableList[this.checkedList[i]].ResumeId);
                this.resumeName.push(this.tableList[this.checkedList[i]].Name);
            }

            let self = this;
            this.$axios.post(1287, {
                ResumeType: value,
                ResumeId: JSON.stringify(self.resumeId)
            }, res => {
                if (res.data.code == 0) {
                    self._empty();
                    self.loadData();
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //取消面试
        InterviewClose() {
            this.InterviewStatus = false;
            this.Reason = '';
            this._empty();
        },
        _InterviewAction() {
            let InterviewId = [];
            for(let i=0;i<this.checkedList.length;i++){
                InterviewId.push(this.tableList[this.checkedList[i]].InterviewId)
            }
            let self = this;
            this.$message.loading('操作中', 0)
            this.$axios.post(1293, {
                Id: JSON.stringify(InterviewId),
                Reason: self.Reason,
                Status: 20
            }, res => {
                this.$message.destroy();
                if (res.data.code == 0) {
                    self.InterviewStatus = false;
                    self._empty();
                    self.loadData();

                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        cancelInterview() {
            if(this.checkedList.length ==0){
                this.$message.error('请先选择要取消面试的简历');
                return false;
            }
            for(let i=0;i<this.checkedList.length;i++){
                this.resumeId.push(this.tableList[this.checkedList[i]].ResumeId);
                this.resumeName.push(this.tableList[this.checkedList[i]].Name);
            }
            this.InterviewStatus = true;
        },
        //添加标签
        _tags() {
            if(this.checkedList.length ==0){
                this.$message.error('请先选择要添加标签的简历');
                return false;
            }
            for(let i=0;i<this.checkedList.length;i++){
                this.resumeId.push(this.tableList[this.checkedList[i]].ResumeId);
                this.resumeName.push(this.tableList[this.checkedList[i]].Name);
            }
            this.tagStatus = true;
        },
        tagClose() {
            this.inputValue = '';
            this.tagStatus = false;
            this.tagValue = [];
            this.resumeId = [];
            this.resumeName =[];
            this._empty();
        },
        handleClosetag(removedTag) {
            const tags = this.tagValue.filter(tag => tag !== removedTag)
            this.tagValue = tags
        },

        showInput() {
            this.inputVisible = true
            this.$nextTick(function () {

                this.$refs.input.focus()
            })

        },

        handleInputChange(e) {
            this.inputValue = e.target.value
        },

        handleInputConfirm() {
            const inputValue = this.inputValue
            let tags = this.tagValue
            if (inputValue && tags.indexOf(inputValue) === -1) {
                tags = [...tags, inputValue]
            }
            Object.assign(this, {
                tags,
                inputVisible: false,
                inputValue: ''
            })
            this.tagValue = tags;
        },
        _tagAction() {
            let self = this;
            if (self.tagValue.length == 0) {
                this.$message.error('请先添加标签');
                return false;
            }
            this.$message.loading('操作中..', 0)
            this.$axios.post(1288, {
                ResumeId: JSON.stringify(self.resumeId),
                Tags: JSON.stringify(self.tagValue)
            }, res => {
                this.$message.destroy();
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    self.tagClose();
                    self.loadData();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        changeIndex(i) {
            if( this.listIndex ==i){
                return false;
            }
            this.listIndex = i;
        },
        _emptyKey() {
            this.listIndex = -1;
        },
    }
}
</script>

<style scoped lang="less">
.interviewList {
    background: #ffffff;
    min-height: 500px;
}
.icon-result{
    color: #FFBE25;
    margin-left: 5px;
    margin-right: 8px;
}
.icon-result-fail{
    transform: rotate(180deg);
    color: #989898;
    margin-left: 5px;
    margin-right: 8px;
}
.InterviewResult{
    color: #989898;
    font-size: 12px;
}
.search {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 12px;
    margin-bottom: 20px;
    margin-top: 20px;
    .search-list {
        position: relative;
        min-width: 138px;
        margin-right: 8px;
        margin-left: 8px;
        border: 1px solid #E8E9EE;
        height: 36px;
        border-radius: 4px;

        .search-model {
            position: absolute;
            left: 0;
            top: 46px;
            width: 500px;
            background: #FFFFFF;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 5px;
            z-index: 1000;
            padding: 15px 18px 0px 18px;

            .search-group {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                .search-label {
                    color: #A8A9AA;
                    font-size: 12px;
                    margin-right: 6px;
                    min-width: 82px;
                }

                .search-group-item {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            .search-bottom {
                display: flex;
                height: 53px;
                background: #F9F9F9;
                border-radius: 0px 0px 5px 5px;
                align-items: center;
                width: 100%;
                padding-right: 20px;
                text-align: right;
                flex-direction: row-reverse;

                .search-bottom-bth {
                    margin-left: 8px;
                }
            }
        }

        .search-list-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 0px 8px;

            .search-list-icon {
                color: #999999;
            }

            .search-list-text {
                margin: 0px 5px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .tree-close {
                    color: #999999;
                    margin-left: 5px;
                    z-index: 1000;
                    font-size: 12px;
                }
            }

            .search-list-down {
                color: #999999;
            }
        }

        .search-tree {
            width: 100%;
            opacity: 0;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 100;
        }
    }

}
.table {
    border-top: 1px solid #E5E7EA;
    .table-list {
        display: flex;
        //align-items: center;
        padding: 9px 0px 19px 20px;
        border-bottom: 1px solid #E5E7EA;
        .table-checkbox{
            width: 46px;
        }
        .table-left {
            flex: 1;
             cursor: pointer;
            .content-info {
                display: flex;
                align-items: center;

                .first-try {
                    background: #00D1DB;
                    color: #ffffff;
                    font-size: 12px;
                    padding: 4px 8px;
                    border-radius: 2px;
                }

                .InterviewType {
                    color: #2994FF;
                    padding: 4px 8px;
                    font-size: 13px;
                    border-radius: 2px;
                    border: 1px solid #2994FF;
                    margin-left: 8px;
                    margin-right: 15px;
                }

                .createTime {
                    color: #121316;
                    font-size: 14px;
                }

                .info-name {
                    color: #121316;
                    font-size: 16px;
                    margin-right: 16px;
                    margin-left: 16px;
                }

                .info-phone {
                    color: #989898;
                    font-size: 14px;
                }
            }
            .table-need-info {
                display: flex;
                align-items: center;
                margin-top: 12px;
                .table-need-info-text {
                    color: #666666;
                    font-size: 13px;
                }

                .table-need-info-avatar {
                    margin-left: 8px;
                    margin-right: 5px;
                }
            }
            .address {
                color: #666666;
                font-size: 13px;
                margin-top: 12px;
            }

        }
        .table-right{
            width:35%;
            display: flex;
            align-items: center;
            .interviewer{
                color: #989898;
                font-size: 13px;
            }
            .interviewer-name{
                 color: #666666;
                 font-size: 14px;
            }
            .interviewer-feedback{
                 color: #2994FF;
                font-size: 14px;
            }

        }
    }
    .table-list:hover {
        background: #F7F8F9 !important;
        cursor: pointer;
    }
}

.tabs {
    display: flex;
    border-bottom: 1px solid #dddfe3;
    padding: 10px;


    .tab-item {
        width: 120px;
        padding-left: 10px;
        /*margin-right: 24px;*/
        cursor: pointer;

        .tab-item-count {
            color: #242424;
            font-size: 24px;
            padding-left: 5px;
            /*text-align: center;*/
        }

        .tab-item-text {
            color: #999999;
            font-size: 12px;
            /*text-align: center;*/
        }
    }

    .tab-item-active {
        position: relative;
    }

    .tab-item-active::after {
        content: '';
        width: 56px;
        height: 2px;
        background: #2994ff;
        position: absolute;
        left: 0;
        bottom: -10px;
    }
}
.foot{
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 40px;

}
.action{
    display: flex;
    align-items: center;
    flex: 1;
    margin-bottom: 20px;
    margin-top: 20px;
    .action-button{
        width: 120px;
        margin-left: 10px;
    }
}
.recommend-group {
    display: flex;
    margin-top: 18px;
    .recommend-group-label {
        width: 120px;
        .recommend-group-label-active {
            color: #ff0000;
        }
    }
    .recommend-group-item {
        width: 438px;
    }
}
.table-list-index {
    background: #F7F8F9 !important;
    cursor: pointer;
}
.Approvers-userList {
    display: flex;
    .Approvers-userList-item {
        margin-right: 12px;
    }
}
</style>