<template>
    <div class="offerApproval">
        <div class="tabs">
            <div
                    :class="tabKey == 'noApprovaledTotal' ? 'tab-item-active' : ''"
                    class="tab-item"
                    @click="_tabs('noApprovaledTotal')"
            >
                <div class="tab-item-count">{{ noApprovaledTotal }}</div>
                <div class="tab-item-text">待审批</div>
            </div>
            <div
                    :class="tabKey == 'ApprovaledTotal' ? 'tab-item-active' : ''"
                    class="tab-item"
                    @click="_tabs('ApprovaledTotal')"
            >
                <div class="tab-item-count">{{ ApprovaledTotal }}</div>
                <div class="tab-item-text">已审批</div>
            </div>
        </div>
        <!--搜索-->
        <div class="search">
            <div class="tag-group">


                <div class="tag-item" v-if="searchList.length>0" v-for="(item,index) in searchList">
                    <a-tag class="tags">{{item.name}}:</a-tag>
                    <a-tag
                            v-if="item.type=='person'"
                            :closable="closable"
                            v-for="(person,index2) in item.value"
                            :key="person.id"
                            @close="() => personClose(index,index2)"
                            class="tags-active tags">
                        <span>{{person.title}}</span>
                    </a-tag>
                    <!--:key="item.value"-->
                    <a-tag
                            v-if="item.type !='person'"
                            :closable="closable"
                            @close="() => searchClose(index)"
                            class="tags-active tags">
                        <span>{{item.value}}</span>
                    </a-tag>
                </div>
            </div>
        </div>
        <!--列表-->
        <div class="table" :style="'width:' +($store.state.app.showNav ? pageWidth - 220 : pageWidth) +'px;'">
            <a-table
                    :data-source="tableList"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: conSelectChange }"
                    :pagination="false"
                    :columns="tableColumns"
                    :scroll="{ x: 1500 }"
            >

                <template slot-scope="text, record" slot="Name" >
                    <div style="white-space: nowrap;" @click="_info(record.ResumeId)">
                        <a-avatar
                                :size="32"
                                icon="user"
                                style="margin-right: 8px"
                                :src="record.UserImage?record.UserImage+'?imageView2/1/w/32/h/32':''"
                        />

                        <span :class="record.ApprovalStatus==3?'Offer-success':record.ApprovalStatus==4?'Offer-refuse':''" style="cursor: pointer;color: #2994ff;">{{ record.Name }}</span>
                    </div>

                </template>
                <template slot-scope="text, record" slot="Remark">
                    <a-tooltip placement="topLeft" :title="record.Remark" arrow-point-at-center>
                        <span class="InterviewAddress-item">{{record.Remark.substr(0,5)}}</span>
                    </a-tooltip>
                </template>
                <template slot-scope="text, record" slot="Sex">
                    {{record.Sex === 0 ? '男' : record.Sex == 1 ? '女' : '未知' }}/
                    {{record.WorkExperience}}年/
                    {{educationOjject[record.Qualification]}}
                </template>
                <template slot-scope="text, record" slot="sendOfferApprovalName">
                    <a-tooltip placement="topLeft" :title="record.sendOfferApprovalName" arrow-point-at-center>
                        <span >{{record.sendOfferApprovalName}}</span>
                    </a-tooltip>
                </template>


                <div slot="Name">
                        <span>候选人 <i class="iconfont icon-shaixuan filter-icon"
                                     @click="_showMOdelTest('content',$event,'候选人','Name')"></i></span>
                </div>
                <div slot="Mobile">
                        <span>联系方式 <i class="iconfont icon-shaixuan filter-icon"
                                      @click="_showMOdelTest('content',$event,'联系方式','Mobile')"></i></span>
                </div>
                <div slot="PositionName">
                        <span>应聘职位 <i class="iconfont icon-shaixuan filter-icon"
                                      @click="_showMOdelTest('content',$event,'应聘职位','PositionName')"></i></span>
                </div>


            </a-table>
        </div>
        <a-pagination
                v-if="total"
                style="margin-top: 35px;text-align: right;"
                v-model="Page"
                @change="ChangePage"
                :pageSize="Limit"
                :total="total"

        />
        <!--简历信息-->
        <a-drawer
                title="简历信息"
                width="1000"
                :closable="false"
                :visible="visible"
                @close="onClose"

        >
            <div style="margin-bottom: 60px">
                <resumeInfo v-if="visible" :Id="ResumeId" :OpenType="'Need'" @visible="onClose"></resumeInfo>
            </div>
        </a-drawer>
        <!--搜索组件-->
        <div id="table-header" style="position:absolute;z-index: 10">
            <tableHeader :showMOdelTest="showMOdelTest" @getData="_tableGetData"></tableHeader>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import tableHeader from '../../components/tableHeader/filterBox';
    import resumeInfo from 'pages/Hr/resumeInfo'
    export default {
        components: {tableHeader,resumeInfo},
        name: "offerApproval",
        data() {
            return {
                pageWidth: '',
                codeUrl: this.$route.query.code,
                statisticsData: [],
                tabKey: 'noApprovaledTotal',
                searchList: [],
                searchArr: [],
                total: '',
                Limit: 10,
                Page: 1,
                tableList: [],
                selectedRowKeys: [],
                tableColumns: [
                    {

                        dataIndex: 'Name',
                        width: 100,
                        align: 'center',
                        slots: {title: 'Name'},
                        scopedSlots: {customRender: 'Name'},
                        fixed:'left'
                    },
                    {
                        title: '性别/经验/学历',
                        dataIndex: 'Sex',
                        width: 140,
                        align: 'center',
                        scopedSlots: {customRender: 'Sex'},
                    },

                    {
                        dataIndex: 'Mobile',
                        width: 120,
                        align: 'center',
                        slots: {title: 'Mobile'},
                    },
                    {
                        dataIndex: 'PositionName',
                        width: 120,
                        align: 'center',
                        slots: {title: 'PositionName'},
                    },
                    {
                        title: '审批发起人',
                        dataIndex: 'sendOfferApprovalUserName',
                        width: 110,
                        align: 'center',
                    },
                    {
                        title: '发起时间',
                        dataIndex: 'sendOfferApprovalTime',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title: '部门',
                        dataIndex: 'DepartmentName',
                        width: 120,
                        align: 'center',
                    },
                    {
                        title: '薪酬',
                        dataIndex: 'Money',
                        width: 60,
                        align: 'center',
                    },
                    {
                        title: '备注',
                        dataIndex: 'Remark',
                        // width: 60,
                        align: 'center',
                        scopedSlots: {customRender: 'Remark'},
                    },
                    {
                        title: '审批人',
                        dataIndex: 'sendOfferApprovalName',
                        width: 160,
                        align: 'center',
                        fixed:'right',
                        scopedSlots: {customRender: 'sendOfferApprovalName'},
                    },

                ],
                educationOjject:
                    {'0': '未知', '1': '初中及以下', '3': '中专/中技术', '2': '高级中学', '4': '大学专科', '6': '大学本科', '8': '硕士研究生', '9': '博士', '10': '博士后', '11': '不限'},
                ApprovaledTotal:0,
                noApprovaledTotal:0,
                showMOdelTest: {
                    show: false,
                    type: 'content',
                    init: ''
                },
                UserApprovalStatus:[0,1,2],//未审批 [3,4] 已审批
                ResumeId:'',
                visible:false,
            }
        },
        props: {
            resumeProgress: {
                type: String
            },
        },
        computed: {
            ...mapState({
                showNav: state => state.app.showNav
            }),

        },
        created() {
            if (this.codeUrl) {
                this.pageWidth = document.documentElement.clientWidth - 100;
            } else {
                this.pageWidth = document.documentElement.clientWidth - 240;
            }
            if (this.resumeProgress == 'offerApproval') {
                this.pageWidth = this.pageWidth - 280;
            }
            this.loadData();
        },
        methods: {
            loadData() {
                let self = this;
                this.$message.loading('加载中....', 0);
                let data = {
                    pagesize: self.Limit,
                    p: self.Page,
                    UserApprovalStatus:JSON.stringify(self.UserApprovalStatus)
                };
                if (this.searchList.length != 0) {
                    for (let i = 0; i < self.searchList.length; i++) {
                        if (self.searchList[i].type == 'person') {
                            let person = [];
                            if (self.searchList[i].value) {
                                for (let s = 0; s < self.searchList[i].value.length; s++) {
                                    person.push(self.searchList[i].value[s].id);
                                }
                            }
                            data[self.searchList[i].field] = person.toString();
                        } else {
                            data[self.searchList[i].field] = self.searchList[i].value;
                        }

                    }
                }
                this.$axios.get(12942,
                    data
                    , res => {
                        this.$message.destroy();
                        if (res.data.code == 0) {
                            self.tableList = res.data.data;
                            self.total = res.data.total;
                            self.noApprovaledTotal = res.data.noApprovaledTotal;
                            self.ApprovaledTotal = res.data.ApprovaledTotal;
                        } else {
                            self.ApprovaledTotal =0;
                            self.noApprovaledTotal =0;
                            self.tableList = [];
                            self.total = '';
                            this.$message.error(res.data.msg);
                        }
                    })
            },
            ChangePage(val) {
                this.Page = val;
                this.loadData();
            },

            searchClose(i) {
                if (this.searchList.length == 1) {
                    this.searchList = []
                } else {
                    this.searchList.splice(i, 1)
                }
                this.Page = 0;
                this.loadData();
            },
            personClose(key, i) {
                let valueArr = this.searchList[key].value;
                if (valueArr.length == 1) {
                    this.searchList.splice(key, 1)
                } else {
                    valueArr.splice(i, 1);
                    this.searchList[key].value = valueArr;
                }
                this.Page = 0;
                this.loadData();
            },
            conSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                // this.resumeId = [];
                // this.resumeName = [];
                // this.InterviewId = [];
                let self = this;
                // for (let i = 0; i < selectedRows.length; i++) {
                //     if (self.resumeId.indexOf(selectedRows[i].ResumeId) == -1) {
                //         self.resumeId.push(selectedRows[i].ResumeId);
                //         self.resumeName.push(selectedRows[i].Name)
                //     }
                //     if (self.InterviewId.indexOf(selectedRows[i].InterviewId) == -1) {
                //         this.InterviewId.push(selectedRows[i].InterviewId)
                //     }
                //
                //
                // }
            },
            _tableGetData(val) {
                let self = this;
                let num = "";
                for (let i = 0; i < self.searchArr.length; i++) {
                    if (self.searchArr[i].field == self.selectType) {
                        num = i;
                        self.searchArr[i].value = val;
                        if (self.searchArr == 'Stage') {
                            self.tabKey = val;
                        }
                    }
                }
                let arr = {};
                arr.field = self.searchArr[num].field;
                arr.name = self.searchArr[num].name;
                arr.type = self.searchArr[num].type;
                arr.value = val;
                if (self.searchList.length == 0) {
                    self.searchList.push(arr);
                } else {
                    let activity = 0;
                    for (let s = 0; s < self.searchList.length; s++) {
                        if (self.searchList[s].field == self.searchArr[num].field) {
                            self.searchList[s].value = val;
                        } else {
                            activity = 1;
                        }
                    }
                    if (activity == 1) {
                        self.searchList.push(arr);
                    }
                }
                this.Page = 0;
                this.loadData();

            },
            _showMOdelTest(type, e, list, txt) {

                this.selectType = txt;
                let init = '';
                if (window.innerWidth - e.x > 300) {
                    document.getElementById('table-header').style.left = e.x - 50 + 'px';
                    document.getElementById('table-header').style.top = e.y + 15 + 'px';
                } else {
                    if (type == 'date') {
                        document.getElementById('table-header').style.left = e.x - 530 + 'px';
                    } else {
                        document.getElementById('table-header').style.left = e.x - 280 + 'px';
                    }
                    document.getElementById('table-header').style.top = e.y + 15 + 'px';
                }
                let arr = {};

                arr.name = list;
                arr.field = txt;
                arr.value = '';
                arr.type = type;
                if (this.searchArr.length == 0) {
                    this.searchArr.push(arr);
                } else {
                    let self = this;
                    let activity = 0;
                    for (let i = 0; i < self.searchArr.length; i++) {
                        if (self.searchArr[i].field == txt) {
                            activity = 1;
                        }
                    }
                    if (activity != 1) {
                        this.searchArr.push(arr);
                    }
                }
                let data = {
                    list: list,
                    type: type,
                    show: true,
                    init: init,
                    event: e
                };

                this.showMOdelTest = data;
            },
            _tabs(val) {
                if(this.tabKey ==val){
                    return false;
                }

                if(this.tabKey=='ApprovaledTotal'){
                    this.UserApprovalStatus =[0,1,2]
                }else{
                    this.UserApprovalStatus =[3,4]
                }
                this.tabKey = val;
                this.Page = 0;
                this.loadData();
            },
            onClose(){
                this.visible = false;
                this.ResumeId ='';
            },
            _info(id){
                this.ResumeId =id;
                this.visible = true;
            },
        }
    }
</script>

<style scoped lang="less">
    .offerApproval {
        width: 100%;
        background: #ffffff;
        min-height: 800px;
    }

    .tabs {
        display: flex;
        border-bottom: 1px solid #dddfe3;
        padding: 10px;
        margin-bottom: 20px;
        .tab-item {
            width: 89px;
            padding-left: 10px;
            /*margin-right: 24px;*/
            cursor: pointer;
            .tab-item-count {
                color: #242424;
                font-size: 22px;
                /*text-align: center;*/
            }
            .tab-item-text {
                color: #999999;
                font-size: 12px;
                /*text-align: center;*/
            }
        }
        .tab-item-active {
            position: relative;
        }
        .tab-item-active::after {
            content: '';
            width: 56px;
            height: 2px;
            background: #2994ff;
            position: absolute;
            left: 0;
            bottom: -10px;
        }
    }

    .Offer-success {
        color: #03BF16 !important;
    }

    .Offer-refuse {
        color: #D9001B !important;
    }

    .InterviewAddress-item {
        display: block;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>