<template>
    <div class="resumeProgress">
        <div class="tree">
            <p class="company">用人部门筛选</p>
            <a-tree v-if="treeData" :selectedKeys="selectedKeys" :treeData="treeData" @select="_treeSelect" default-expand-all/>
        </div>
        <div class="content" :style="'background:'+(dataList.length==0?'#ffffff':'')" >
            <div class="list" v-if="dataList.length>0 && urlKey=='resumeProgress' " v-for="(item,index) in dataList">
                <div class="list-head">
                    <div class="list-head-title">{{item.PositionName}}</div>
                    <div class="list-head-text">{{item.CreateTime}} {{item.CreateUserName}}创建丨{{item.DepartmentName}}丨{{item.PositionName}}丨招聘负责人：{{item.InchargeUserName}}</div>
                    <div v-if="item.CheckStatus==1">

                        <div class="list-head-time" v-if="item.Status !=1 && item.StartRecruitDate">于{{item.StartRecruitDate}}  开始招聘，已招聘{{item.alreadyRecruitDay}}天</div>
                        <div class="list-head-time" v-if="item.Status==1">已完成招聘，招聘时长{{item.alreadyRecruitDay}}天</div>
                        <div class="list-head-time" v-if="item.Status !=1 && !item.StartRecruitDate">尚未开始招聘,请联系HR:{{item.InchargeUserName}},尽快招聘</div>
                    </div>

                    <div class="list-head-time" v-if="item.CheckStatus==0">需求待审批，请尽快去提醒审批人</div>
                    <div class="list-head-time" v-if="item.CheckStatus==3">需求审批中，请尽快去提醒审批人</div>
                    <div class="list-head-time" style="color: red" v-if="item.CheckStatus==2">需求审批已拒绝</div>
                    


                </div>
                <div class="list-content">
                    <div class="list-content-group" @click="_resume(item.Id,'waitScreen',item.PositionName,item.InchargeUserName,item.PositionName,item.InchargeUserName)">
                        <div class="list-content-num">{{item.waitScreen}}</div>
                        <div class="list-content-text">待筛选简历</div>
                    </div>
                    <div class="list-content-group"  @click="_resume(item.Id,'waitArrangeInterview',item.PositionName,item.InchargeUserName)">
                        <div class="list-content-num">{{item.waitArrangeInterview}}</div>
                        <div class="list-content-text">待安排面试</div>
                    </div>
                    <div class="list-content-group" @click="_resume(item.Id,'interviewing',item.PositionName,item.InchargeUserName)">
                        <div class="list-content-num">{{item.interviewing}}</div>
                        <div class="list-content-text">面试进行中</div>
                    </div>
                    <div class="list-content-group" @click="_resume(item.Id,'communicateOffer',item.PositionName,item.InchargeUserName)">
                        <div class="list-content-num" >{{item.communicateOffer}}</div>
                        <div class="list-content-text">沟通offer</div>
                    </div>
                    <div class="list-content-group" @click="_resume(item.Id,'waitEntry',item.PositionName,item.InchargeUserName)">
                        <div class="list-content-num">{{item.waitEntry}}</div>
                        <div class="list-content-text">待入职</div>
                    </div>
                    <div class="list-content-group" @click="_resumeType(item.Id,'realityEntry',item.PositionName,item.InchargeUserName)">
                        <div class="list-content-num">{{item.realityEntry}}</div>
                        <div class="list-content-text">实际入职</div>
                    </div>
                    <div class="list-content-group" @click="_resumeType(item.Id,'eliminated',item.PositionName,item.InchargeUserName)">
                        <div class="list-content-num">{{item.eliminated}}</div>
                        <div class="list-content-text">已淘汰</div>
                    </div>
                </div>
                <!--<div v-if="dataList.length>1" class="list-background"></div>-->
            </div>
            <resume v-if="urlKey=='resumeScreen' || urlKey=='myIsDemandUser' || urlKey=='myIsInterviewer'" :ProgressStage="ProgressStage" :ProgressNeedId="ProgressNeedId" :OpenType="'Need'" :resumeProgress="urlKey" :InchargeUserPositionName="InchargeUserPositionName"></resume>

            <interviewList v-if="urlKey=='myIsInterviewerInterviewList' || urlKey=='myIsDemandUserInterviewList'"  :OpenType="'Need'" :resumeProgress="urlKey"></interviewList>
            <offerApproval v-if="urlKey=='offerApproval'" :resumeProgress="urlKey"></offerApproval>
            <resumePersonnel v-if="urlKey=='resumePersonnel'" :ProgressStage="ProgressStage" :ProgressNeedId="ProgressNeedId" :OpenType="'Need'" :resumeProgress="urlKey"></resumePersonnel>

        </div>
    </div>
</template>

<script>
    import resume from 'pages/Hr/resume'
    import interviewList from 'pages/Hr/interviewList'
    import offerApproval from 'pages/Hr/offerApproval'
    import resumePersonnel from 'pages/Hr/resumePersonnel'

    export default {
        components:{resume,interviewList,offerApproval,resumePersonnel},
        name: "resumeProgress",
        data() {
            return {
                selectedKeys:['0-0-0'],
                treeData: '',
                dataList:[],
                urlKey:'resumeProgress',
                ProgressNeedId:'',
                ProgressStage:'',
                InchargeUserPositionName:'',
            }
        },
        created() {
            this.lodaData();
            this.getTree();
        },
        methods: {
            lodaData(){
                let self = this;
                this.$message.loading('加载中...',0);
                this.$axios.get(12940,{

                },res=>{
                    this.$message.destroy();
                    if(res.data.code==0){
                        self.dataList = res.data.data;
                        console.log(self.dataList);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            getTree(){
                let self = this;
                this.$axios.get(12941,{},res=>{
                    if(res.data.code==0){
                        self.treeData = res.data.data;
                        console.log(self.treeData)
                    }else{
                        self.treeData ='';
                    }
                })
            },
            _treeSelect(key, e) {
                this.selectedKeys = key;
                if(e.selected){
                    console.log(e.selectedNodes[0].data.props.value);
                    if(e.selectedNodes[0].data.props.value){
                        this.urlKey = e.selectedNodes[0].data.props.value;
                        if(this.urlKey=='resumeProgress'){
                            this.lodaData();
                        }
                    }else{
                        // this.urlKey = 'resumeProgress';
                    }
                }



            },
            _resume(needId,val,PositionName,InchargeUserName){
                this.urlKey ='resumeScreen';
                this.ProgressNeedId = needId;
                this.ProgressStage = val;
                this.InchargeUserPositionName = PositionName+'('+ InchargeUserName+')';

            },
            _resumeType(needId,val,PositionName,InchargeUserName){
                this.urlKey ='resumePersonnel';
                this.ProgressNeedId = needId;
                this.ProgressStage = val;
                this.InchargeUserPositionName = PositionName+'('+ InchargeUserName+')';
            }
        }
    }
</script>

<style scoped lang="less">
    .resumeProgress {
        display: flex;
        min-height: 600px;
        /*padding-bottom: 60px;*/
    }

    .tree {
        width: 220px;
        background: #ffffff;
        padding: 15px;
        border-radius: 5px;
        .company {
            color: #242424;
            font-size: 14px;
        }
    }

    .content {
        flex: 1;
        margin-left: 16px;
        border-radius: 5px;
        padding-bottom: 60px;

        .list {
            background: #ffffff;
            margin-bottom: 20px;
            .list-head {
                padding: 0px 8px 0px 15px;
                display: flex;
                height: 45px;
                align-items: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.09);
                .list-head-title {
                    color: #242424;
                    font-size: 16px;
                    margin-right: 22px;
                }
                .list-head-text {
                    flex: 1;
                    color: #666666;
                    font-size: 12px;
                }
                .list-head-time {
                    color: #666666;
                    font-size: 12px;
                }
            }
            .list-content {
                display: flex;
                padding-left: 25px;
                .list-content-group {
                    padding: 29px 48px 41px 0px;
                    cursor: pointer;
                    .list-content-num {
                        color: #2994FF;
                        font-size: 16px;
                        margin-bottom: 16px;
                    }
                    .list-content-text {
                        color: #666666;
                        font-size: 12px;
                    }
                }
            }
        }
        .list-background{
            width: 100%;
            height: 20px;
            background: #f0f0f8;
        }
    }

</style>